
const state = {
    navKey:0
}
const mutations = {
   
}
const actions = {}
const getters = {}
 
export default {
    state,
    mutations,
    actions,
    getters
}

