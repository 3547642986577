
const state = {
isShowPop:false,//是否展示弹窗
whichShow:0, //0-选择对比商品 1-对比商品 2-购买页 3-活动规则 4-出价记录 5-价格趋势 6-出价 7-出价权 8-保证金 9-评价
poptitle:'对比商品',//弹窗标题
priceText:'柴门价',
price:1,
orderBtn:'收入囊中',
orderBtnColor:'#876235',
orderBtnBadge:0,
isDisabled:false,    //是否禁用下单按钮
type:0,
compareData:[],
buyGoodsData:{},
goodsId:0
 
}
const mutations = {
   
}
const actions = {

}
const getters = {}
 
export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}

