
import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
 
import goods from './modules/goods'
 
import brand from './modules/brand'
import user from './modules/user'
 
const store = new Vuex.Store({
    modules:{
        goods,
        brand,
        user
    }
})
export default store