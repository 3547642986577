
const state = {
  
   userInfo: {
        "token":"xxxx",
        "userId":0,
        "nickname":"xxxx",
        "gender":"xxxx",
        "avatar":"xxxx",
        "bindRealName":false,//是否实名认证
        "birthday":"xxxx",
      }  
     
    }
    const mutations = {
       
    }
    const actions = {
    
    }
    const getters = {}
     
    export default {
        namespaced:true,
        state,
        mutations,
        actions,
        getters
    }
    
    