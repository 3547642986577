import Vue from "vue";
import VueRouter from "vue-router";
import myvideo from "@/views/myvideo";
import image from "@/views/image";
import index from "@/views/index";
import app from "@/views/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/app",
    name: "app",
    component: app,
  },
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/images",
    name: "images",
    component: image,
  },
  {
    path: "/myvideo",
    name: "myvideo",
    meta: {
      keepAlive: true, // 设置页面缓存
    },
    component: myvideo,
  },
  {
    path: "/annotation", //批注
    name: "annotation",
    component: () => import("@/views/annotation"),
  },
  {
    path: "/history", //历史的今天
    name: "history",
    component: () => import("@/views/history"),
  },
  {
    path: "/ownerShare", //大众人分享
    name: "ownerShare",
    component: () => import("@/views/ownerShare"),
  },
  {
    path: "/preface", //卷首
    name: "preface",
    component: () => import("@/views/preface"),
    meta: {
      keepalive: true,
    },
  },
  {
    path: "/goodsDetails", //商品详情页
    name: "goodsDetails",
    component: () => import("@/views/goodsDetails"),
  },
  {
    path: "/brandHistory", //品牌历史页
    name: "brandHistory",
    component: () => import("@/views/brandHistory"),
  },
  {
    path: "/buySucess", //购买成功页
    name: "buySucess",
    component: () => import("@/views/buySucess"),
  },
  {
    path: "/advanceDeposit", //预交保证金
    name: "advanceDeposit",
    component: () => import("@/views/advanceDeposit"),
  },
  {
    path: "/customSorting", //自定义排序
    name: "customSorting",
    component: () => import("@/views/customSorting"),
  },
  {
    path: "/bridge", //自定义排序
    name: "bridge",
    component: () => import("@/views/bridge"),
  },

  {
    path: "/activeRules",
    name: "activeRules",
    component: () => import("@/views/activeRules"),
  },

  {
    path: "/errorPage",
    name: "errorPage",
    component: () => import("@/views/errorPage"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//     // window.entryUrl = location.href.split('#')[0]
//
// })
// router.afterEach((to, from,failure) =>{
// if (failure){
//     if (failure.code === 400){
//         this.$router.push("errorPage")
//     }
// }
// })

export default router;
