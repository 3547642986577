import Vue from "vue";
// 在这里引入你所需的组件
import {
  Button,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Popup,
  Form,
  Field,
  Uploader,
  RadioGroup,
  Radio,
  CellGroup,
  Toast,
  Tab,
  Tabs,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Icon,
  Sticky,
  Popover,
  List,
  Cell,
  Lazyload,
  Loading,
  PullRefresh,
  Empty,
  ImagePreview,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  ShareSheet,
  ActionSheet,
  SwipeCell,
  Card,
  Checkbox,
  CheckboxGroup,
  Grid,
  GridItem,
  Sku,
  Badge,
  Stepper,
  AddressEdit,
  NumberKeyboard,
  Area,
  Picker,
  Rate,
  Tabbar, TabbarItem,
  SubmitBar,
  Dialog,
  CountDown

} from "vant";

// 按需引入UI组件
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(CellGroup);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Sticky);
Vue.use(Popover);
Vue.use(List);
Vue.use(Cell);
Vue.use(Lazyload);
Vue.use(Loading);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(ImagePreview);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(ShareSheet);
Vue.use(ActionSheet);
Vue.use(SwipeCell);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Sku);
Vue.use(Badge);
Vue.use(Stepper);
Vue.use(AddressEdit);
Vue.use(NumberKeyboard);
Vue.use(Area);
Vue.use(Picker);
Vue.use(Rate);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(SubmitBar);
Vue.use(Dialog);
Vue.use(CountDown);