
//判断安卓ios
function selectVersion() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return "iOS";
    } else if (userAgent.match(/Android/i)) {
      return "Android";
    } else {
      return "unknown";
    }
  }


function BridgeFun(data,respFunction){
    if(selectVersion() == 'iOS') {
        window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data))
    } else {
        window.ssmAndroid.ssmMessage(JSON.stringify(data))
    }
    window.respFunction=respFunction()
}
export default BridgeFun