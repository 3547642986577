import Axios from "axios";
//导入md5
import md5 from "js-md5";
import dayjs from "dayjs";
import config from "@/config/index.js";
import { Toast } from "vant";
import store from "../store";
import router from "@/router";
import BridgeFun from '@/utils/bridge'
userInfo();
const request = Axios.create({
  baseURL: config.serverUrl,
  timeout: 5000,
});
request.interceptors.request.use(
  (config) => {
    const key = "A34992KS945KS040W0087";
    const once = dayjs(new Date()).format("YYYYMMDDHHmmss");
    const sign = md5(key + once);
    config.headers.once = once;
    config.headers.sign = sign;
    config.headers.token = localStorage.getItem("token");
    // config.headers.token = 'c2d176fbd1da4a799bd302cb47450d12';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器   校验
request.interceptors.response.use(
  //用于处理判断请求成功失败
  (response) => {
    if (response.data.code === 200) {
      return response.data; // 表示放行
    } else if (response.data.code === 1003 || response.data.code === 900) {
      console.log("登录情况", response.data.code);
      lognFun();
      return response.data;
    }else if (response.data.code === 11013 || response.data.code === 11015) {
      console.log("支付失败情况", response.data.code);
      let req = {
        "url": `https://${process.env.VUE_APP_ENV}-app-h5-merchant.serenetone.com/#/merchans/ensure`,
        "title": "",
        "showNav": "0"  //0 不显示标题导航。1显示
      }
      let data = {
            action:'OpenPage',
            req,
            reqId:6,
            respFunction:'openFn'
        }
        console.log('ll',data);
        
      BridgeFun(data,openFn)
      return response.data;
    } else {
      console.log(response.data.code);
      //错误处理
      // 通过状态码，判断是什么错误
      Toast.fail(response.data.msg);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    Toast.fail("服务器异常，请稍后重试");
    return Promise.reject(error);
  }
);
//判断安卓ios
function selectVersion() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPod/i)
  ) {
    return "iOS";
  } else if (userAgent.match(/Android/i)) {
    return "Android";
  } else {
    return "unknown";
  }
}

//登陆
function lognFun() {
  var data = {
    action: "Login",
    req: {},
    reqId: 7,
    respFunction: "loginfun",
  };
  if (selectVersion() == "iOS") {
    window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data));
  } else {
    window.ssmAndroid.ssmMessage(JSON.stringify(data));
  }
  window.loginfun = loginfun;
}
function loginfun(e) {
  console.log("登陆的回调", e);
  if(e.code!==200) return
  let { data } = e;
  localStorage.setItem("token", data.token);
  store.state.user.userInfo = data;
}
// 获取用户信息
function userInfo() {
  var data = {
    action: "UserInfo",
    req: {},
    reqId: 5,
    respFunction: "UserInfofn",
  };
  if (selectVersion() == "iOS") {
    window.webkit.messageHandlers.ssmIOS.postMessage(JSON.stringify(data));
  } else {
    window.ssmAndroid.ssmMessage(JSON.stringify(data));
  }
  window.UserInfofn = UserInfofn;
}
// 获取用户信息的回调
function UserInfofn(e) {
  if (e.code !== 200) {
    lognFun();
  }
  let { data } = e;
  console.log("用户信息的回调", e);
  localStorage.setItem("token", data.token);
  store.state.user.userInfo = data;
}
// 支付失败跳转订单列表的回调
function openFn(e) {
  console.log("打开新页面", e);
}
export default request;
